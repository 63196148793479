<template>
  <div :class="`absolute w-screen h-screen flex text-yellow-light bg-opacity-90 justify-start bg-cover items-center bg-buildings bg-center`">
    <div class="absolute w-screen h-screen flex  flex-col align-center text-yellow-light bg-midnight-blue bg-opacity-95 justify-center items-center">
      <div class="w-10/12 1480:w-1/2 text-4xl z-0 font-bold flex flex-col">
        <h2 class="medium-title mb-4">{{title}}</h2>
        <p class="grand-text">{{text}}</p>
        <div class="text-center w-1/4 self-center min-w-min">
          <ShadowButton class="mt-40 w-24" theme="light-yellow-midnight-blue" @click="$emit('nextstep', {action: 'next', transition: 'up'})">Continuer</ShadowButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShadowButton from "../../components/ShadowButton";


export default {
  emits: ["nextstep"],
  components: {
    ShadowButton
  },
  props: {
    'text': String,
    'title': String,
  }
}
</script>

<style lang="scss" scoped>

</style>
